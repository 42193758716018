import React from 'react'
import useSound from 'use-sound'
import backgroundMusic1 from './MusicTechnology.mp3'

export const SoundContext = React.createContext(null)

export const SoundProvider = ({ children }) => {
  const [sound, {stop}] = useSound(backgroundMusic1, { loop: true });
  const [isPlay, setPlay] = React.useState(false);

  const handleClick = () => {
    if (isPlay) {}
      else {
        setPlay(true);
        sound();
      }
  }

  const handleClickOff = () => {
    setPlay(false);
    stop();
  }

  return (
      <SoundContext.Provider value={[isPlay, handleClick, handleClickOff]}>
          {children}
      </SoundContext.Provider>
  );
}
